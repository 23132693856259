import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Container } from "react-bootstrap"

const CustomNavbar = () => {
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container fluid>
          <Link to="/" className="link-no-style"></Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="mr-auto"
              style={{
                width: "100%",
                justifyContent: "space-between",
                borderBottom: "2px solid",
                 fontSize:'16px'
              }}
            >
              <Link to="/" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="home">
                  Home
                </Nav.Link>
              </Link>

              <Link to="/poetry" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="poetry">
                  Poetry
                </Nav.Link>
              </Link>
              <Link to="/pearson-award" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="novel">
                  Pearson Award
                </Nav.Link>
              </Link>
              <Link to="/short-story" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="short-story">
                  Short Story
                </Nav.Link>
              </Link>
              <Link to="/spoken-word" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="spoken-word">
                  Spoken Word
                </Nav.Link>
              </Link>
              <Link to="/film" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="film">
                  Film
                </Nav.Link>
              </Link>
              <Link to="/commissions" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="commisions">
                  Commissions
                </Nav.Link>
              </Link>
              <Link to="/playhouse" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="the-playhouse">
                  The Playhouse
                </Nav.Link>
              </Link>
              <Link to="/prison-arts-foundation" className="link-no-style">
                <Nav.Link
                  as="span"
                  id="navbar-link"
                  eventKey="prison-arts-foundation"
                >
                  Prison Arts Foundation
                </Nav.Link>
              </Link>
              <Link to="/podcast" className="link-no-style">
                <Nav.Link
                  as="span"
                  id="navbar-link"
                  eventKey="podcast"
                >
                  Podcasts
                </Nav.Link>
              </Link>
              <Link to="/contact" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="contact">
                  Contact
                </Nav.Link>
              </Link>
              <Link to="/bio" className="link-no-style">
                <Nav.Link as="span" id="navbar-link" eventKey="bio">
                  Bio
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
